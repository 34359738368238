




import InsuranceCC from '/src/class-components/insurance/InsuranceCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    InsuranceCC
  }
})
export default class Insurance extends Vue {}
