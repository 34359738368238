var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        ref: "tableComponent",
        attrs: {
          fields: _vm.fields,
          addEditLink: {
            name: "ins-company-details",
            params: {
              id: _vm.formData.id ? _vm.formData.id.toString() : "new",
            },
          },
          itemsRepo: _vm.insuranceRepo,
          "load-params": { OnlyWaitingForReview: _vm.showOnlyWaitingForReview },
          isDataFromModel: false,
          isAddEditSidbar: false,
          copyData: _vm.copyData,
          sidebarData: _vm.formData,
          getByIdOnEditFilter: function () {
            return false
          },
          "is-selectable": true,
        },
        on: {
          "update:copyData": function ($event) {
            _vm.copyData = $event
          },
          "update:copy-data": function ($event) {
            _vm.copyData = $event
          },
          "update:sidebarData": function ($event) {
            _vm.formData = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.formData = $event
          },
          "row-clicked": _vm.rowClicked,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(selected)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: { disabled: true },
                  model: {
                    value: data.rowSelected,
                    callback: function ($$v) {
                      _vm.$set(data, "rowSelected", $$v)
                    },
                    expression: "data.rowSelected",
                  },
                }),
              ]
            },
          },
          {
            key: "search-right",
            fn: function () {
              return [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.showOnlyWaitingForReview,
                          callback: function ($$v) {
                            _vm.showOnlyWaitingForReview = $$v
                          },
                          expression: "showOnlyWaitingForReview",
                        },
                      },
                      [_vm._v("Show only waiting for review\n        ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "Add-left",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticStyle: { padding: "5px" } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          disabled: !_vm.isAnySelected,
                        },
                        on: { click: _vm.mapInsurances },
                      },
                      [_vm._v("Map Insurances\n        ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                _c("b", [_vm._v(_vm._s(data.item.name))]),
                _c("br"),
                _c("span", { staticClass: "smaller-text" }, [
                  _vm._v(
                    "ID: " +
                      _vm._s(data.item.id) +
                      " | SC: " +
                      _vm._s(data.item.code)
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(isIpa)",
            fn: function (data) {
              return [
                data.item.isIpa
                  ? _c("span", [_vm._v("Yes")])
                  : _c("span", [_vm._v("No")]),
              ]
            },
          },
          {
            key: "cell(status-right)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  [
                    data.isTemp
                      ? _c("b-icon-exclamation-triangle-fill", {
                          staticClass: "mr-1",
                          attrs: { variant: "danger" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("MappingSidebar", {
        attrs: {
          open: _vm.showSidebar,
          insurances: _vm.checkedInsurances,
          base: _vm.baseInsuranceId,
        },
        on: {
          "update:open": function ($event) {
            _vm.showSidebar = $event
          },
          "update:insurances": function ($event) {
            _vm.checkedInsurances = $event
          },
          "update:base": function ($event) {
            _vm.baseInsuranceId = $event
          },
          onSubmitSuccess: _vm.onSubmitSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }